import React from 'react';

import Layout from '@components/Layout/Layout';
import Seo from '@components/Seo/Seo';
// import Hero from '@components/Hero/Hero';
import HeroWinter from '@components/HeroWinter/HeroWinter';
// import EBikes from '@components/EBikes/EBikes';
import WinterGoods from '@components/WinterGoods/WinterGoods';

const IndexPage = ({ location }) => {
  return (
    <Layout location={location} map>
      <Seo
        title="Noleggio Sci e E-Bike Champoluc | Tiziano Bieller Sport"
        lang="it"
      />
      <HeroWinter />
      <WinterGoods />
    </Layout>
  );
};

export default IndexPage;
